<template>
  <div class="home pd60">
    <div class="top">
      <!-- <img src="@/assets/banner.png" /> -->
      <div class="my-banner-box flex-start">
        <div class="banner flex-start">
          <a-avatar
            :src="userInfo.userAvatar"
            :size="40"
            icon="user"
            class="mr10"
          />
          <div @click="$router.push('/my-detail')">
            <h5 class="fz18">
              {{ userInfo.realName }}
              <a-icon type="right" :style="{ fontSize: '14px' }" />
            </h5>
            <span class="fz12 fl-light">{{ userInfo.userMobile }}</span>
          </div>
        </div>
      </div>
      <div class="menus fz12 pt20">
        <a-row :gutter="[30, 30]">
          <a-col :span="6" @click="sendTo('/order', '2')">
            <a-badge style="margin-top: 1px" :count="forHandleNumber"
              ><i class="iconfont">
                <img src="@/assets/dcl.svg" />
              </i>
            </a-badge>
            <div><span>待处理</span></div>
          </a-col>
          <a-col :span="6" @click="sendTo('/order', '3')"
            ><i class="iconfont">
              <img src="@/assets/ycl.svg" />
            </i>
            <div><span>已处理</span></div></a-col
          >
          <a-col :span="6" @click="sendTo('/order', '1')"
            ><i class="iconfont">
              <img src="@/assets/ytj.svg" />
            </i>
            <div><span>已提交</span></div></a-col
          >
          <a-col :span="6" @click="sendTo('/order', '4')"
            ><i class="iconfont">
              <img src="@/assets/cgx.svg" />
            </i>
            <div><span>待提交</span></div></a-col
          >
        </a-row>
      </div>
    </div>
    <div class="main">
      <div class="main-content bg-block mt10">
        <div
          class="my-item border-bottom flex-between flex-center"
          @click="$router.push('/invite')"
        >
          <div>
            <span class="iconfont icon-hps-appyaoqinghaoyou"></span>
            <span class="ml10 ft-light">邀请同事</span>
          </div>
          <a-icon type="right" style="color: #bdbdbd" />
          <!-- <span class="ft-grey"></span> -->
        </div>
        <div
          class="my-item border-bottom flex-between flex-center"
          @click="$router.push('/feedback')"
        >
          <div>
            <span class="iconfont icon-hps-appyijianfankui"></span>
            <span class="ml10 ft-light">意见反馈</span>
          </div>
          <a-icon type="right" style="color: #bdbdbd" />
        </div>
        <div class="my-item flex-between flex-center">
          <div>
            <span class="iconfont icon-hps-appbanbenguanli"></span>
            <span class="ml10 ft-light">版本号</span>
          </div>
          <div>
            <span class="ft-light">V1.0</span
            ><a-icon type="right" style="color: #bdbdbd" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/footer/index";
import { row, col, Button, Avatar, Badge, Icon } from "ant-design-vue";
import { getOrderItemsNumber, getUserInfo } from "@/utils/api";
export default {
  data() {
    return {
      forHandleNumber: 0,
      userInfo: {},
    };
  },
  components: {
    Footer,
    ARow: row,
    ACol: col,
    // AButton: Button,
    AAvatar: Avatar,
    ABadge: Badge,
    AIcon: Icon,
  },
  methods: {
    sendTo(path, index) {
      this.$router.push({ path, query: { index } });
    },
    async getOrderItemsNumber() {
      const { data } = await getOrderItemsNumber();
      this.forHandleNumber = data.forHandleNumber;
    },
    async getUserInfo() {
      const { data, resp_code, resp_msg } = await getUserInfo();
      if (resp_code == 0) {
        this.userInfo = data;
      }
    },
  },
  computed: {
    defaultImg() {
      return `this.src="${require("@/assets/img/default.png")}"`;
    },
  },
  created() {
    this.getUserInfo();
    this.getOrderItemsNumber();
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  .top {
    text-align: center;
    img {
      max-width: 75rem;
      width: 100%;
      height: auto;
    }
    .menus {
      margin: 0 12px;
      background: #fff;
      position: relative;
      margin-top: -3.9rem;
      height: 9.1rem;
      border-radius: 8px;
      padding: 0 3.2rem;
      .iconfont {
        img {
          width: 2.6rem;
        }
      }
    }
    .reprot-btn {
      border-radius: 4.4rem !important;
      width: 100%;
      height: 4.6rem;
    }
    .home-btn-box {
      margin-top: 2.3rem;
      padding: 0 8%;
    }
  }
}

.main-content {
  padding: 0 1.4rem;
  .content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.4rem;
    span {
      border-left: 3px solid #f39800;
      display: inline-block;
      height: 1.5rem;
      line-height: 1.5rem;
      padding-left: 8px;
    }
  }
  p {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 300;
    line-height: 1.8rem;
  }
  .info-right {
    .iconfont {
      position: relative;
      top: -1.5rem;
    }
  }
  .order-info {
    img {
      width: 10.4rem;
      height: 7.5rem;
      border-radius: 0.3rem;
      display: block;
    }
  }
}
.my-item {
  padding: 1.8rem 0;
}
.my-banner-box {
  width: 100%;
  max-width: 75rem;
  //   height: 351px;
  background: #f39800;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  .banner {
    margin: 0 3.2%;
    padding: 3.5rem 0 7.4rem 0;
    color: #fff;
    h5 {
      color: #fff;
      margin: 0;
    }
  }
}
</style>